import { createStore } from 'vuex';
import { CadMap, CommandFactory, LTEntity } from '@/webcad/webcad';
import { rgbToHex } from '@/utils/index';
import { getUserConfig } from '@/api/userApi';
import { getUserInfo, setUserMember } from '@/utils/userAuth';
export interface State {
  cad_Map: CadMap | null;
  colorList: string[];
  colorSelectList: string[];
  userInfo: null;
  exportData: null;
  userConfigs: string[];
  userMemuData: null;
}
function getCommonUser() {
  let commandArr: any = [];
  let commandKeys = CommandFactory.getInstance().commandList().keys();
  //配置默认的命令列表;
  let default_key_map = new Map<string, string>();
  default_key_map.set('EXPLODE', 'X');
  default_key_map.set('PLINE', 'PL');
  default_key_map.set('LINE', 'L');
  default_key_map.set('CIRCLE', 'C');
  default_key_map.set('ARC', 'A');
  default_key_map.set('TEXT', 'T');
  default_key_map.set('MOVE', 'M');
  default_key_map.set('EXTEND', 'EX');
  default_key_map.set('TRIM', 'TR');
  for (let command_name of commandKeys) {
    let temp_simple_command_name = default_key_map.get(command_name);

    if (temp_simple_command_name !== undefined) {
      temp_simple_command_name = '';
    }
    let command = CommandFactory.getInstance().getCommand(command_name);
    commandArr.push({
      command_name: command_name,
      simple_command_name: temp_simple_command_name || '',
      keyword_command_name: '',
      simpleName: '',
      keyword: '',
      sname: command && command.commandTip ? command.commandTip : '',
    });
  }
  return commandArr;
}
import bus from '@/utils/bus';
export const store = createStore<State>({
  state() {
    return {
      cad_Map: null,
      colorList: [],
      colorSelectList: [],
      userInfo: null,
      exportData: null,
      userConfigs: [],
      userMemuData: null,
    };
  },
  mutations: {
    setUserInfo(state, data) {
      state.userInfo = data;
    },
    resetCadMap(state, data) {
      state.cad_Map = data;
    },
    resetColors(state, data) {
      state.colorList = data;
    },
    resetSelectColors(state, data) {
      let layerLegth = state.cad_Map?.select_command.selectedFeatures().length;
      let bylayerColor = '#fff';
      if (layerLegth == 1) {
        let entity =
          state.cad_Map?.select_command.selectedFeatures()[0] as LTEntity;
        if (state.cad_Map == null) return;
        let color = state.cad_Map.database.getLayerColor(entity.getLayerName());
        bylayerColor = rgbToHex(color.red, color.green, color.blue);
      }

      let colorArr: any = state.colorList.slice(0, 7);
      colorArr.unshift({
        index: -2,
        name: 'byBlock',
        color: '#fff',
      });
      colorArr.unshift({
        index: -1,
        name: 'byLayer',
        color: bylayerColor,
      });
      colorArr.push({
        index: 300,
        color: '#f78a3d',
      });
      state.colorSelectList = colorArr;
      bus.emit('getColorList');
    },
    beginMacro(state, type: string = 'modify') {
      state.cad_Map?.undoStack().beginMacro(type);
    },
    refreshCad(state, isMacro: boolean = true) {
      if (isMacro) state.cad_Map?.undoStack().endMacro();
      state.cad_Map?.select_command.render();
    },
    setExportData(state, data) {
      state.exportData = data;
    },
    returnUserConfig(state) {
      let userInfo = getUserInfo();
      let commandArr = [] as any,
        dataArr = [] as any;
      return new Promise((resolve) => {
        if (userInfo) {
          getUserConfig().then((res: any) => {
            if (res && res.data) {
              if (res.data.config && typeof res.data.config) {
                dataArr = JSON.parse(res.data.config);
              }
            }
            if (dataArr && dataArr.length > 0) {
              dataArr.map((item: any) => {
                let command = CommandFactory.getInstance().getCommand(
                  item.command_name
                );
                commandArr.push({
                  ...item,
                  simpleName: item.simple_command_name,
                  keyword: item.keyword_command_name,
                  sname:
                    command && command.commandTip ? command.commandTip : '',
                });
              });
            } else commandArr = getCommonUser();
            state.userConfigs = commandArr;
          });
        } else {
          state.userConfigs = getCommonUser();
          resolve({
            id: '',
            configs: getCommonUser(),
          });
        }
      });
    },
    setUserMember(state, data) {
      state.userMemuData = data;
      console.log('setUserMember', data);
    },
  },
});
